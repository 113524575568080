// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/Blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-competitors-js": () => import("./../../../src/pages/Competitors.js" /* webpackChunkName: "component---src-pages-competitors-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/Contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-form-js": () => import("./../../../src/pages/Form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-horses-js": () => import("./../../../src/pages/Horses.js" /* webpackChunkName: "component---src-pages-horses-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pictures-js": () => import("./../../../src/pages/Pictures.js" /* webpackChunkName: "component---src-pages-pictures-js" */),
  "component---src-pages-pony-club-js": () => import("./../../../src/pages/PonyClub.js" /* webpackChunkName: "component---src-pages-pony-club-js" */),
  "component---src-pages-prices-js": () => import("./../../../src/pages/Prices.js" /* webpackChunkName: "component---src-pages-prices-js" */),
  "component---src-templates-competitors-template-js": () => import("./../../../src/templates/competitors-template.js" /* webpackChunkName: "component---src-templates-competitors-template-js" */),
  "component---src-templates-galery-template-js": () => import("./../../../src/templates/galery-template.js" /* webpackChunkName: "component---src-templates-galery-template-js" */),
  "component---src-templates-horses-template-js": () => import("./../../../src/templates/horses-template.js" /* webpackChunkName: "component---src-templates-horses-template-js" */),
  "component---src-templates-pictures-template-js": () => import("./../../../src/templates/pictures-template.js" /* webpackChunkName: "component---src-templates-pictures-template-js" */)
}

